
import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import SettingService from '../../../../services/setting-service'
import {
  JobModule
} from '../../../../store/JobModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import QuantityWidget from '../../../../widget/Quantity.vue'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'JobComplete',
    components: {
      QuantityWidget
    }
  })
export default class JobComplete extends Vue {
    protected isRemark = false
    protected isExist = false
    protected inventory: any = []
    protected completedDate: any = null

    get job () {
      return JobModule.job
    }

    /* @Watch('job', {
      deep: true
    })
    checkJobLineCategory () {
      if (this.job.lines.find(line => line.category_name === 'Oil')) this.isExist = true
      else this.isExist = false
    } */

    mounted () {
      this.boot()
    }

    protected boot () {
      this.isRemark = true;
      (this.$refs.QuantityWidget as any).show(this.job)
      this.populateInventoryData()
      // this.populatecompleteData()
    }

    protected async populateInventoryData () {
      const response = await SettingService.getInventoryData(this.job.id)
      if (response.data === 'empty') this.isExist = false
      else {
        this.isExist = true
        this.inventory = response.data
      }
    }

    protected saveJobComplete () {
      const data: any = {
        job_id: this.job.id,
        completed_date: this.job.completed_date,
        inventory_id: this.inventory.id,
        oil_quantity: this.inventory.oil_quantity,
        water_quantity: this.inventory.water_quantity,
        modified_user: AuthModule.user.fullname
      }
      SettingService.complete(data).then(response => {
        ToastModule.message(response.data.message)
        JobModule.setJobDetails(this.job.id)
      }).catch(error => {
        console.log(error)
      })
    }
}

