import APIService from '@/services/api-service'
const resource = '/job'

class SettingService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobDetails (jobId: any) {
    return this.connector.get(`job/repository/${jobId}/details`)
  }

  public update (job: any) {
    return this.connector.post(`${resource}/settings/update`, job)
  }

  public complete (data: any) {
    return this.connector.post(`${resource}/complete/data`, { data: data })
  }

  /* public getCompleteData (jobId: any) {
    return this.connector.get(`${resource}/complete-data/${jobId}`)
  } */

  public getInventoryData (jobId: any) {
    return this.connector.get(`${resource}/complete/${jobId}/inventory-data`)
  }

  public approve (data: any) {
    return this.connector.put(`${resource}/settings/approve`, data)
  }
}

export default new SettingService()
