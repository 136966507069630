
import {
  Component,
  Vue
} from 'vue-property-decorator'
import SummaryService from '../services/summary-service'
  @Component({
    name: 'QuantityWidget',
    components: {}
  })
export default class QuantityWidget extends Vue {
    protected progress = 100
    protected job: any = {}
    protected dispatchDifference = 0
    protected receivedDifference = 0
    protected inventories: any = []

    get receivables () {
      return this.inventories.filter(service => service.service_type === 'Oil')
    }

    public show (job: any) {
      this.populateJobDetail(job)
      this.populateJobInventories(job)
    }

    protected async populateJobDetail (job: any) {
      const response = await SummaryService.getJobDetail(job.id)
      this.job = response.data
    }

    protected async populateJobInventories (job: any) {
      this.dispatchDifference = 0
      this.receivedDifference = 0

      const response = await SummaryService.getJobInventories(job.id)
      response.data.forEach(service => {
        this.inventories.push({
          id: service.id,
          quantity: service.quantity,
          dispatched_quantity: service.dispatched_quantity,
          received_quantity: service.received_quantity,
          service_id: service.service_id,
          service_type: service.service_type,
          unit_of_measurement: service.unit_of_measurement
        })

        if (service.service_type === 'Oil') {
          this.dispatchDifference = (service.dispatched_quantity / service.quantity) * 100
          this.receivedDifference = (service.received_quantity / service.dispatched_quantity) * 100
        }
      })
    }

    protected cancel () {
      this.reset(this.job)
      this.dispatchDifference = 0
      this.receivedDifference = 0
      this.inventories = []
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

